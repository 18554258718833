import React from 'react';
import { FaGithub, FaLink, FaNodeJs } from 'react-icons/fa';
import {
    ProjectDiv,
    Row,
    ServiceBox,
    ServiceBoxHeader,
    ServiceBoxP,
    A,
    BoxDiv,
} from './Styles';
import { Container, Common, H1 } from '../../SharedStyles/SharedStyles';
import { AiFillGithub } from 'react-icons/ai';
import { FaHtml5, FaReact, FaJs, FaCss3 } from 'react-icons/fa';
import { SiMongodb, SiNodeDotJs, SiRedux, SiHeroku } from 'react-icons/si';
// import { SiExpress, SiMaterialui } from 'react-icons/si/SiExpress';

import { v4 as uuidv4 } from 'uuid';

const Projects = () => {
    const projects = [
        {
            id: 1,
            project_name: 'Zee5',
            image: 'https://github.com/prayaschhetri1/portfolio-pictures/blob/master/images/zee5.png?raw=true',
            project_desc:
                ' A web application for streaming the latest movies, tv shows and web series on the go through phone or pc. Features: Sign-in/create account page Highly rated movies and web series Variety of content yet very easy to use',
            deploy_link: 'https://zee5-one.vercel.app/',
            repo_link: 'https://github.com/durgeshsoni/zee-5-',
            tech_stack: [<FaHtml5 />, <FaCss3 />, <FaJs />],
        },
        {
            id: 2,
            project_name: 'CricInfo',
            image: 'https://github.com/prayaschhetri1/portfolio-pictures/blob/master/images/Screenshot%20(140).png?raw=true',
            project_desc:
                'ESPN cricinfo (formerly known as Cricinfo or CricInfo) is a sports news website exclusively for the game of cricket. The site features news, articles, live coverage of cricket matches (including liveblogs and scorecards), and StatsGuru, a database of historical matches and players from the 18th century to the present.',
            deploy_link: 'https://crickinfo.netlify.app/',
            repo_link: 'https://github.com/Aakash-kushwaha/Honest--wing-5796',
            tech_stack: [
                <FaHtml5 />,
                <FaCss3 />,
                <FaJs />,
                <FaNodeJs />,
                <SiMongodb />,
                <SiHeroku />,
            ],
        },
        {
            id: 3,
            project_name: 'Jcrew',
            image: 'https://github.com/prayaschhetri1/portfolio-pictures/blob/master/images/Screenshot%20(146).png?raw=true',
            project_desc:
                "A web application for shopping branded and newly launch cloths. Features: Signup/Login page. Category wise colths. Diffrent filters for the user's best experience .",
            deploy_link: 'https://snazzy-horse-6fab01.netlify.app/',
            repo_link: 'https://github.com/yash-2507/J.Crew-Clone',
            tech_stack: [
                <FaReact />,
                <SiRedux />,
                // <SiMaterialui />,
                <SiMongodb />,
                // <SiExpress />,
                <AiFillGithub />,
                <FaJs />,
                <FaNodeJs />,
            ],
        },
        
    ];

    return (
        <Container className='projects'>
            <ProjectDiv>
                <Common>
                    <H1>Projects</H1>
                </Common>
                <Row>
                    {projects.map((item) => (
                        <BoxDiv key={uuidv4()}>
                            <ServiceBox>
                                <img
                                    src={item.image}
                                    alt='icon'
                                    style={{ width: '100%' }}
                                />
                                <div style={{ margin: '25px' }}>
                                    <ServiceBoxHeader
                                        style={{ textAlign: 'center' }}
                                    >
                                        {item.project_name}
                                    </ServiceBoxHeader>
                                    <ServiceBoxP>
                                        {item.project_desc}
                                    </ServiceBoxP>
                                    {/* <a href="">Read more</a> */}
                                    <div
                                        style={{
                                            margin: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {item.tech_stack.map((item) => (
                                            <div
                                                key={uuidv4()}
                                                className='iconsTechStack'
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                    <A
                                        href={item.repo_link}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <FaGithub /> Code
                                    </A>
                                    <A
                                        href={item.deploy_link}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <FaLink /> Demo
                                    </A>
                                </div>
                            </ServiceBox>
                        </BoxDiv>
                    ))}
                </Row>
            </ProjectDiv>
        </Container>
    );
};

export default Projects;
